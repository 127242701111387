<template>
  <div class="common_sub_container">
    <h2>PASSWORD MANAGEMENT</h2>
    <section>
      <div class="two_tabs">
        <a
          @click="showTab1=true,showTab2=false"
          v-bind:class="{ active: showTab1 }"
          class="tab_buttons"
        >LOCKED USERS</a>
        <a
          @click="showTab2=true,showTab1=false"
          v-bind:class="{ active: showTab2 }"
          class="tab_buttons"
        >MANAGE PASSWORDS</a>
      </div>
      <LockedUsers v-show="showTab1" />
      
      <ManagePasswords v-show="showTab2" />
    </section>
  </div>
</template>
<script>
import LockedUsers from "./LockedUsers.vue";
import ManagePasswords from "./ManagePasswords.vue";
export default {
  name: "PasswordManagement",
  components: {
    LockedUsers,
    ManagePasswords
  },
  data() {
    return {
      showTab1: true,
      showTab2: false
    };
  },
  created(){
    this.$emit("breadCrumbData",["Password Management"]);
  }
};
</script>


<!-- <style  scoped >
</style> -->


<style lang="scss">

$theme-color: #06A5ED;
.jobs_table {
	border-top: 5px solid $theme-color;

	table {
		width: 100%;
		border-collapse: collapse;
		border-left: 1px solid #ccc;
		font-size: 14px;
		margin-bottom: 15px;

		tr {
			th {
				background: #004a7c;
				padding: 20px 10px;
				color: #ccc;
				font-weight: 700;
				border: 1px solid #222;
				border-left: 0;
				border-top: 0;
				text-align: left;
			}

			td {
				background: #eee;
				padding: 20px 10px;
				color: #333;
				font-weight: 400;
				border: 1px solid #ccc;
				border-left: 0;
				border-top: 0;
				text-align: left;
				vertical-align: top;

				p {
					padding: 0 0 10px 0;

					b {
						font-weight: 700;
					}
				}

				p.text-center {
					text-align: center;
				}

				span.label {
					display: inline-block;
					font-weight: 700;
					background: #000;
					color: #fff;
					border-radius: 4px;
					padding: 5px;
					text-align: center;
					margin-bottom: 5px;

					&.green {
						background: #46aa42;
					}
				}

				.icons_wrapper {
					padding: 10px 0;
					display: flex;
					justify-content: space-between;
					position: relative;

					a {
						display: inline-block;
						width: 24px;
						height: 24px;

						.material-icons {
							font-size: 18px;
							position: relative;
							top: 0;
							margin: 0;
							font-weight: normal;

							&.blue {
								color: rgb(45, 91, 241);
							}

							&.red {
								color: rgb(241, 74, 45);
							}

							&.green {
								color: #46aa42;
							}

							&.orange {
								color: orange;
							}

							&.grey {
								color: grey;
							}

						}
					}
				}

				.bt {
					width: 120px;
					height: 40px;
					text-align: center;
					line-height: 40px;
					border-radius: 4px;
					display: block;
					margin: 5px 0;
				}

				.bt_black {
					background: #063657;
					color: #fff;
				}

				.bt_gold {
					background: $theme-color;
					color: #000;
				}
			}
		}
	}
}

.two_tabs {
	padding: 40px 10px 0 10px;
	display: flex;
	justify-content: space-around;

	.tab_buttons {
		display: block;
		width: 40%;
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: #ececec;
		color: #333;
		border: 1px solid #ddd;

		span.label {
			display: none;
			background: $theme-color;
			color: #000;
			padding: 5px 10px;
			border-radius: 4px;
		}
	}

	.tab_buttons.active {
		background: #004a7c;
		color: #fff;
	}
}

.view_optons_wrapper {
	width: 240px;
	height: auto;
	background: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
	position: absolute;
	left: -250px;
	top: 10px;
	padding: 10px;

	a.view_option_button {
		width: 100% !important;
		height: 40px !important;
		background: $theme-color;
		color: #000;
		display: block;
		margin-bottom: 10px;
		text-align: center;
		line-height: 40px;
	}

	a.view_option_button:last-child {
		background: #aaa;
		color: #000;

		margin-bottom: 0;
	}
}
</style>